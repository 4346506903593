// File 2 verbs
export const verbs = [
    {
        infinitive: 'become',
        german: 'werden',
        thirdPerson: 'becomes',
        simplePast: 'became',
        pastPerfect: 'become',
        progressive: 'becoming',
    },
    {
        infinitive: 'catch',
        german: 'fangen',
        thirdPerson: 'catches',
        simplePast: 'caught',
        pastPerfect: 'caught',
        progressive: 'catching',
    },
    {
        infinitive: 'cut',
        german: 'schneiden',
        thirdPerson: 'cuts',
        simplePast: 'cut',
        pastPerfect: 'cut',
        progressive: 'cutting',
    },
    {
        infinitive: 'drive',
        german: 'fahren',
        thirdPerson: 'drives',
        simplePast: 'drove',
        pastPerfect: 'driven',
        progressive: 'driving',
    },
    {
        infinitive: 'fall',
        german: 'fallen',
        thirdPerson: 'falls',
        simplePast: 'fell',
        pastPerfect: 'fallen',
        progressive: 'falling',
    },
    {
        infinitive: 'fights',
        german: 'kämpfen',
        thirdPerson: 'fights',
        simplePast: 'fought',
        pastPerfect: 'fought',
        progressive: 'fighting',
    },
    {
        infinitive: 'fly',
        german: 'fliegen',
        thirdPerson: 'flies',
        simplePast: 'flew',
        pastPerfect: 'flown',
        progressive: 'flying',
    },
    {
        infinitive: 'hear',
        german: 'hören',
        thirdPerson: 'hears',
        simplePast: 'heard',
        pastPerfect: 'heard',
        progressive: 'hearing',
    },
    {
        infinitive: 'hide',
        german: 'verstecken',
        thirdPerson: 'hides',
        simplePast: 'hid',
        pastPerfect: 'hidden',
        progressive: 'hiding',
    },
    {
        infinitive: 'hold',
        german: 'halten',
        thirdPerson: 'holds',
        simplePast: 'held',
        pastPerfect: 'held',
        progressive: 'holding',
    },
    {
        infinitive: 'hurt',
        german: 'verletzen',
        thirdPerson: 'hurts',
        simplePast: 'hurt',
        pastPerfect: 'hurt',
        progressive: 'hurting',
    },
    {
        infinitive: 'keep',
        german: 'halten',
        thirdPerson: 'keeps',
        simplePast: 'kept',
        pastPerfect: 'kept',
        progressive: 'keeping',
    },
    {
        infinitive: 'leave',
        german: 'verlassen',
        thirdPerson: 'leaves',
        simplePast: 'left',
        pastPerfect: 'left',
        progressive: 'leaving',
    },
    {
        infinitive: 'lie',
        german: 'liegen',
        thirdPerson: 'lies',
        simplePast: 'lay',
        pastPerfect: 'lain',
        progressive: 'lying',
    },
    {
        infinitive: 'read',
        german: 'lesen',
        thirdPerson: 'reads',
        simplePast: 'read',
        pastPerfect: 'read',
        progressive: 'reading',
    },
    {
        infinitive: 'ride',
        german: 'reiten',
        thirdPerson: 'rides',
        simplePast: 'rode',
        pastPerfect: 'ridden',
        progressive: 'riding',
    },
    {
        infinitive: 'say',
        german: 'sagen',
        thirdPerson: 'says',
        simplePast: 'said',
        pastPerfect: 'said',
        progressive: 'saying',
    },
    {
        infinitive: 'sleep',
        german: 'schlafen',
        thirdPerson: 'sleeps',
        simplePast: 'slept',
        pastPerfect: 'slept',
        progressive: 'sleeping',
    },
    {
        infinitive: 'sleep',
        german: 'schlafen',
        thirdPerson: 'sleeps',
        simplePast: 'slept',
        pastPerfect: 'slept',
        progressive: 'sleeping',
    },
    {
        infinitive: 'tell',
        german: 'erzählen',
        thirdPerson: 'tells',
        simplePast: 'told',
        pastPerfect: 'told',
        progressive: 'telling',
    },
    {
        infinitive: 'think',
        german: 'denken',
        thirdPerson: 'thinks',
        simplePast: 'thought',
        pastPerfect: 'thought',
        progressive: 'thinking',
    },
    {
        infinitive: 'throw',
        german: 'werfen',
        thirdPerson: 'throws',
        simplePast: 'threw',
        pastPerfect: 'thrown',
        progressive: 'throwing',
    },
    {
        infinitive: 'write',
        german: 'schreiben',
        thirdPerson: 'writes',
        simplePast: 'wrote',
        pastPerfect: 'written',
        progressive: 'writing',
    },
];
